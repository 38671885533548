import { withMembersArea, MA_APP_IDS } from '@wix/members-area-integration-kit';

const photoSharingAppDefId = '476dc2dd-e16e-43b0-a0aa-1a486c78fbe2';

let appToken;
let sdk;

const getSharedGalleryEditorApi = () => ({
  editorReady: async function (_editorSDK, _appToken) {
    appToken = _appToken;
    sdk = _editorSDK;
  },
  handleAction: function (args) {
    var type = args.type, payload = args.payload;
    try {
      switch (type) {
        case 'appInstalled':
          switch (payload.appDefinitionId) {
            case photoSharingAppDefId: {
              return sdk.pages.data.getAll(appToken)
                .then(allPages => {
                  var filesPage = allPages.find(page => page.tpaPageId === 'Shared Gallery' && page.tpaApplicationId === photoSharingAppDefId);
                  return filesPage && sdk.document.pages.navigateTo(appToken, { pageLink: { type: 'PageLink', pageId: filesPage.id } });
                })
                .then(() => sdk.document.save());
            }
            default:
              return Promise.resolve();
          }
        default:
          return Promise.resolve();
      }
    }
    catch (e) {
      Promise.reject();
      throw e;
    }
  },
})

const editorApp = withMembersArea(getSharedGalleryEditorApi(), {
  membersAreaApps: [
    MA_APP_IDS.ALL_MEMBERS,
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.SHARED_GALLERY,
  ]
});

export const editorReady = editorApp.editorReady;
export const handleAction = editorApp.handleAction;
